import React from "react"
import { Tabs, Tab } from "react-bootstrap"

import Layout from "../components/layout"
import ComponentProyecto from "../hooks/proyecto"
import ComponentObjetivo from "../hooks/objetivo"
import ComponentMision from "../hooks/mision"
import ComponentIntegrantes from "../hooks/equipo"
import SEO from "../components/seo"



const Proyecto = ({  }) => (
  <Layout pageInfo={{ pageName: "El Proyecto" }}>
    <SEO title="El Proyecto" />
    <br />
    <br />
    <br></br>
    <Tabs defaultActiveKey="proyecto" id="uncontrolled-tab-example" className="mb-5 nav-fill integrantes-tab">
      <Tab eventKey="proyecto" style={{textAlign: `justify`}} className="pt-3" title="Descripción del Proyecto">
        <ComponentProyecto></ComponentProyecto>
      </Tab>
      <Tab eventKey="Objetivo" style={{textAlign: `justify`}} className="pt-3" title="Objetivos">
        <ComponentObjetivo></ComponentObjetivo>
      </Tab>
      <Tab eventKey="equipo" style={{textAlign: `justify`}} className="pt-3" title="Equipo de Investigación">
      <ComponentIntegrantes></ComponentIntegrantes>
      </Tab>
      <Tab eventKey="mision" style={{textAlign: `justify`}} className="pt-3" title="Misión y Visión">
        <ComponentMision></ComponentMision>
      </Tab>
    </Tabs>
  </Layout>
)
export default Proyecto

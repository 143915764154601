import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ComponentMision = () => {
    const data = useStaticQuery(graphql`
    {
      nodeContenidoEdutoolkit(id: {eq: "7d9d0e1d-c40f-5f1b-9df1-aff9784afa33"}) {
        title
        body {
          value
        }
        created
      }
    }
  `)
    return (
        <div>
        <div dangerouslySetInnerHTML={{ __html: data.nodeContenidoEdutoolkit.body.value }}></div>
        </div>
    )
}

export default ComponentMision


import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { Image } from "react-bootstrap"
import { Row, Col } from "react-bootstrap"


const ComponentIntegrantes  = () => {
    const data = useStaticQuery(graphql`
    {
        lider: allNodeIntegrantes(sort: {order: DESC, fields: field_nombre}, filter: {relationships: {field_categoria: {name: {eq: "Líder"}}, field_pagina: {elemMatch: {name: {eq: "eduToolKit"}}}}}) {
            edges {
              node {
                field_nombre
                field_apellido
                id
                path {
                  alias
                }
                body {
                  value
                }
                relationships {
                  field_fotografia {
                    uri {
                      url
                    }
                  }
                  field_categoria {
                    name
                  }
                  field_campus {
                    name
                  }
                  field_pais {
                    relationships {
                      field_bandera {
                        uri {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          
          allParticipantes: allNodeIntegrantes(sort: {order: ASC, fields: field_nombre}, filter: {relationships: {field_categoria: {name: {in: ["Especialista", "Profesor investigador", "Profesora investigadora","Estudiante Investigador","Estudiante Investigadora"]}},, field_pagina: {elemMatch: {name: {eq: "eduToolKit"}}}}}) {
            edges {
              node {
                field_nombre
                field_apellido
                id
                path {
                  alias
                }
                body {
                  value
                }
                relationships {
                  field_fotografia {
                    uri {
                      url
                    }
                  }
                  field_categoria {
                    name
                  }
                  field_campus {
                    name
                  }
                  field_pais {
                    relationships {
                      field_bandera {
                        uri {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
    }`
    )
    return (
        <>
    <Row className="justify-content-md-center">
      {data.lider.edges.map(({ node }) => (
        <Col sm={6} style={{textAlign: `-webkit-center`}}>
          <div class="image-area">
            <div class="img-wrapper">
              <Image src={"https://dev-openresearchlab.pantheonsite.io/" + node.relationships.field_fotografia.uri.url} alt={node.field_nombre} fluid />
              <h3>{node.field_nombre} {node.field_apellido}</h3>
              <Link to={node.path.alias}><p>{node.relationships.field_categoria.name}
                </p></Link>
              <ul>
                <li><Image src={"https://dev-openresearchlab.pantheonsite.io/" + node.relationships.field_pais.relationships.field_bandera.uri.url} style={{ width: `60px`, height: `60px` }} alt={node.field_nombre} /></li>
              </ul>
            </div>
          </div>
        </Col>
      ))}
    </Row>
    <br/>
    <Row className="justify-content-md-center">
      
      {data.allParticipantes.edges.map(({ node }) => (
        <Col sm={3} style={{textAlign: `-webkit-center`}}>
          <div class="image-area">
            <div class="img-wrapper">
              <Image src={"https://dev-openresearchlab.pantheonsite.io/" + node.relationships.field_fotografia.uri.url} alt={node.field_nombre} fluid />
              <h3>{node.field_nombre} {node.field_apellido}</h3>
              <Link to={node.path.alias}><p>{node.relationships.field_categoria.name}
               </p></Link>
              <ul>
                <li><Image src={"https://dev-openresearchlab.pantheonsite.io/" + node.relationships.field_pais.relationships.field_bandera.uri.url} style={{ width: `60px`, height: `60px` }} alt={node.field_nombre} /></li>
              </ul>
            </div>
          </div>
          
        </Col>
      ))}
    </Row>
    </>
)
}
export default ComponentIntegrantes


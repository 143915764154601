import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ComponentObjetivo = () => {
    const data = useStaticQuery(graphql`
    {
      nodeContenidoEdutoolkit(id: {eq: "e7e1d76b-356e-596b-af06-cdd60557c759"}) {
        title
        body {
          value
        }
        created
      }
    }
  `)
    return (
        <div>
        <div dangerouslySetInnerHTML={{ __html: data.nodeContenidoEdutoolkit.body.value }}></div>
        </div>
    )
}

export default ComponentObjetivo


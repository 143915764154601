import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ComponentName = () => {
    const data = useStaticQuery(graphql`
    {
      nodeContenidoEdutoolkit(id: {eq: "998df644-c869-5eb0-8634-7e25a6e9cc00"}) {
        title
        body {
          value
        }
        created
      }
    }
  `)
    return (
        <div>
        <div dangerouslySetInnerHTML={{ __html: data.nodeContenidoEdutoolkit.body.value }}></div>
        </div>
    )
}

export default ComponentName

